















































import Vue from 'vue';
import {mapGetters, mapMutations, mapActions} from 'vuex'
// import debounce from 'lodash.debounce'

export default Vue.extend({
  name: 'Lobby',
  props: {
  },
  data () {
    return {
      teamNameInput:    '',
      teamMembersInput: '',
    }
  },
  computed: {
    ...mapGetters([
      'uid',
      'scenario',
      'session',
      'team',
      'allTeams',
    ]),
    disableReadyButton () {
      const teamName = this.teamNameInput.trim()
      const members = this.teamMembersInput.trim()
      return (!(teamName && members))
    },
    disableStartButton () {
      let teamsChecked = 0
      for (let teamId in this.allTeams) {
        if (!this.allTeams[teamId].ready) return true
        teamsChecked++
      }
      return (teamsChecked) ? false : true
    }
  },
  methods: {
    // debouncedSetTeamName () {
    //   console.log('debouncedSetTeamName', debounce)
    //   debounce(()=>{console.log('foo')}, 1)
    // },
    setTeamName () {
      this.$store.dispatch('updateMyTeam', {name: this.teamNameInput})
    },
    setReady () {
      const members = this.teamMembersInput.split(/[\r\n,\t]+/)
      let m=0
      while (m<members.length) {
        members[m] = members[m].trim()
        if (members[m]) {
          m++
        } else {
          members.splice(m, 1)
        }
      }
      this.$store.dispatch('updateMyTeam', {
        name:    this.teamNameInput,
        members: members,
        ready:   true
      })
    },
    startScenario () {
      if (this.team.completedIntroduction) {
        this.$router.push({name: this.team.lastLocation})
      } else {
        this.$router.push('/introduction')
      }
    },
  },
  mounted () {
    console.log('Lobby.mounted')
    console.log('  team', this.team)
    this.teamNameInput = this.team.name
    this.teamMembersInput = this.team.members.join("\n")
  },
  watch: {
    team (newVal, oldVal) {
      this.teamNameInput = newVal.name
      this.teamMembersInput = newVal.members.join("\n")
    },
    'session.started' (newVal, oldVal) {
      if (newVal && !oldVal) {
        this.$router.push('/introduction')
      }
    }
  }
});
